import React, {useEffect} from 'react';

const HistoryPhilosophy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return(
        <main id="HistoryPhilosophy">
            <article className="article">
                <h1>History and Philosophy</h1>
                <p>Lorem ipsum is placeholder text commonly used in the gralishing industries for previewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in ries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, prineviewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in ries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, prineviewing layouts and visual mockups.</p>
            </article>
        </main>
    )
}

export default HistoryPhilosophy;